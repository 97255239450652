import { TableRow } from '../../../../shared/table/table.interfaces';

export interface SubmittedTrips {
  picup: number[] | null;
  contract: number[] | null;
}

export interface TripsTableData {
  unassigned?: TableRow[];
  picup: TableRow[];
  contract: TableRow[];
  courier: TableRow[];
  count?: number;
  unrouted?: TableRow[];
  previouslySaved?: boolean;
  hasFetched?: boolean;
}

export interface TripCostsResponse {
  config: {};
  data: {
    trip_costs: TripData[];
    unrouted: TripData[];
    summary: TripSummaryData[];
  };
  headers: {};
  request: {};
  status: number;
  statusText: string;
}

export interface TripSummaryData {
  bucket_id: number;
  count: number;
  distance_back_to_depot_in_km: number | null;
  distance_in_km: number | null;
  driver_earnings: number | null;
  driver_earnings_back_to_depot: number | null;
  fixed_price: number;
  parcels: number;
  parcs_price: number;
  parcs_price_back_to_depot: number | null;
  routific_distance_in_km: number;
  stops: number;
  trip_type: string | null;
  type: number | null;
}

export interface TripData {
  bucket_id: number;
  business_id: string;
  delivery_date: string;
  delivery_shift: string | null;
  distance_back_to_depot_in_km: string | null;
  distance_in_km: number;
  driver_earnings: number;
  driver_earnings_back_to_depot: string | null;
  duration: string;
  fixed_price: number;
  geocode_suburbs: string;
  is_valid_picup: boolean;
  parcel_count: number;
  parcs_price: number;
  parcs_price_back_to_depot: string | null;
  parcs_price_incl_vat: number;
  parcs_price_vat: number;
  picup_id: number | null;
  request_id: number;
  routific_distance_in_km: number;
  stop_count: number;
  suburbs: string;
  trip_id: number;
  vehicle: string;
  vehicle_id: string;
  warehouse_id: string;
}

export interface TripSummary {
  distance: number;
  stops: number;
  parcels: number;
  fixedCost: number;
  fixedCostPerParcel: number;
  parcsEstimate: number;
  actualCostPerParcel: number;
}

export interface SelectedTripsResponse {
  config: {};
  data: SelectedTripsIds;
  headers: {};
  request: {};
  status: number;
  statusText: string;
}

export interface SelectedTripsIds {
  contract?: number[];
  picup?: number[];
  unassigned?: number[];
  courier?: number[];
  hasFetched?: boolean;
  Contractor?: number[];
  Picup?: number[];
}
