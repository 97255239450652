import { WarehouseTimesOut } from 'app/interfaces/auth.interfaces';

export interface GetLotteryRadius {
  custom_lottery_radius: number | null;
}

export interface GetLotteryOverflow {
  contractor_lottery_overflows_to_picup: boolean;
}

export interface GetPlusDriversWithWarehouseGeoData {
  error_number: number;
  output: {
    drivers: any[],
    warehouse: { longitude: number, latitude: number }
  }
}

export interface GetBusinessDetailsResponse {
  business_id: string;
  is_deleted: boolean;
  is_eligible_for_da_consolidation: boolean;
  can_consolidate_warehouses: boolean;
  is_schedule_lottery_job: boolean;
  name: string;
  package: string;
  payment_terms: string;
  phone: string;
  receive_freight_offers: boolean;
  registration_number: boolean;
  supported_geocode_country_codes: string[];
  vat_number: string;
  version: number;
  wallet_available_credits: number;
}

export interface PictureProofsOut {
  all_photos_required: boolean,
  failed_collection_photo_required: boolean,
  failed_delivery_photo_required: boolean,
  delivery_photo_required: boolean
}

export interface PictureProofsIncoming {
  RequiresConfirmPhoto_All: boolean,
  RequiresConfirmPhoto_FailedCollection: boolean,
  RequiresConfirmPhoto_FailedDelivery: boolean,
  RequiresConfirmPhoto_Delivery: boolean
}

export interface OperationalSettings{
  qrScanningOptions: QrScanningOptions;
  operationalHours: WarehouseTimesOut[];
}

export interface QrScanningOptions {
  override_waybill_scanning_at_collection: boolean;
  override_waybill_scanning_at_delivery: boolean
}

export interface QrScanningFull {
  OverrideWaybillScanningAtCollection: boolean
  OverrideWaybillScanningAtDelivery: boolean
  OverrideWaybillScanningLastUpdatedTime: string
  OverrideWaybillScanningLastUpdatedUser: string
  WarehouseId: string
  WarehouseName: string
}

export interface OperatingHours {
  dayOfTheWeek: string;
  openingTime: string;
  closingTime: string;
}

export interface QrScanningReturn {
  OverrideWaybillScanningAtCollection: boolean
  OverrideWaybillScanningAtDelivery: boolean
  OverrideWaybillScanningLastUpdatedTime: string
  OverrideWaybillScanningLastUpdatedUser: string
  AggregateId: string;
  OperatingHours: WarehouseTimes[]
}

export interface WarehouseTimes {
  day: string;
  openingTime: string;
  closingTime: string;
}


export interface WarehouseDays {
  day: string;
  openingFormName: string;
  closingFormName: string;
}

export interface DayOfTheWeekFormData {
  Friday: boolean
  FridayClosed: string;
  FridayOpen: string;
  Monday: boolean;
  MondayClosed: string;
  MondayOpen: string;
  Saturday: boolean
  SaturdayClosed: string;
  SaturdayOpen: string;
  Sunday: boolean;
  SundayClosed: string;
  SundayOpen: string;
  Thursday: boolean;
  ThursdayClosed: string;
  ThursdayOpen: string;
  Tuesday: boolean;
  TuesdayClosed: string;
  TuesdayOpen: string;
  Wednesday: boolean;
  WednesdayClosed: string;
  WednesdayOpen: string;
}
